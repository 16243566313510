import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubHeader from "../components/subHeader"

import InstagramSection from "../components/instagramSection"
import { Container } from "@mui/material"

// temporary
import image from "../images/trial.jpg"

const Contact = () => {
  return (
    <Layout>
      <Seo title="Thank you" />
      <SubHeader title="Thank you" image={image} />
      <section className="thankYou">
        <Container>
          {/* <span>Thank you</span> */}
          <h3>Thank you for making an enquiry with Jandakot Heli-Co.</h3>
          <p>One of our team members will get back to you within 48 hours.</p>
          <hr />
        </Container>
      </section>

      <InstagramSection />
    </Layout>
  )
}

export default Contact
